import { count } from 'console';
import { title } from 'process';
import styled from 'styled-components';
import { ICompetition } from '~/../../core/types';
import VideoSearchRefinementForm from '~/components/forms/VideoSearchRefinementForm';
import GridResultsTitle from '~/components/GridResultsTitle';
import { VideosByLocation } from '~/components/grids';
import { CustomHead } from '~/components/Head';
import PageHeaderLayout from '~/components/layouts/PageHeaderLayout';
import StaticPageLayout from '~/components/layouts/StaticPageLayout';
import TeamAvatar from '~/components/TeamAvatar';
import members from '~/data/team-members';
import content from '../Wfa2011Page/content';
import {
  Hero,
  HeroText,
  HeroTextContainer,
  Section,
  SectionContent,
  SectionImage,
  SectionPanel,
  SectionText
} from '~/components/StaticPageCommon';
import VideoCard from '~/components/cards/VideoCard';
import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { faPlay as faPlaySolid } from '@fortawesome/pro-solid-svg-icons';

import { faBriefcase } from '@fortawesome/pro-regular-svg-icons';
import { faBriefcase as faBriefcaseSolid } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isVideographerUser, secsToTimeStr, VerticalSpacer } from '~/utils/LSTVUtils';
import { ABSOLUTE_WHITE } from '~/globals';
import router from 'next/router';
import Avatar from '~/components/Avatar';
import { eventFromUnknownInput } from '@sentry/browser/dist/eventbuilder';
import VendorCard from '~/components/cards/VendorCard';
import breakpoints from '~/globals/breakpoints';
import { isMobile, isMobileOnly } from 'react-device-detect';
import DateAndContent from '../VideoPage/DateAndContent';
import { Media } from '~/styles/media';
import { BooleanSchema } from 'yup';
import HorizontalScroll from '~/components/HorizontalScrollWrapper';
import ChipsSelect from '~/components/ChipsSelect';
import { iteratee } from 'lodash';
import { CtaColorScheme, CtaLink } from '../../../components/cta/regular';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import RealisticConfetti from '~/components/Confetti';
import Confetti from '~/components/Confetti';
import LSTVLink from '~/components/LSTVLink';
import LSTVButton from '~/components/LSTVButton';
import * as LSTVGlobals from '~/globals';
import { useAuthService } from '~/api/hooks/useAuthService';
import { faLongArrowUp } from '@fortawesome/pro-light-svg-icons';

const CompetitionHeader = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.theme.grey_1_0};
  display: flex;
  flex-direction: row;

  @media ${breakpoints.isTablet} {
  }

  @media ${breakpoints.isMobile} {
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: none;
  }
`;

const CompetitionBadge = styled.img`
  width: 189px;
  height: 189px;
  border-radius: 1000px;
  background: none;

  @media ${breakpoints.isTablet} {
    order: 0;
  }

  @media ${breakpoints.isMobile} {
    width: 100%;
    order: 0;
  }
`;

const CompetitionDesc = styled.div`
  margin-right: 85px;

  @media ${breakpoints.isTablet} {
    margin-right: 0;
    order: 1;
  }

  @media ${breakpoints.isMobile} {
    margin-right: 0;
    order: 1;
    border-bottom: 1px solid #e6e6e6;
  }
`;

const ResultItems = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const ResultItemWinner = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  @media ${breakpoints.isMobile} {
    flex-direction: column;
    margin-bottom: 40px;
  }

  @media ${breakpoints.isTablet} {
    flex-direction: column;
    margin-bottom: 80px;
  }
`;

const TieBar = styled.div`
  width: 100%;
  background: #B86747;
  margin-bottom: 15px;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 40px;
  line-height: 1.7rem;

`;

const ResultItemThumbnailWinner = styled.div<{
  even: boolean;
  url: string;
}>`
  position: relative;
  min-width: 800px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  height: 450px;
  order: ${(props) => (props.even ? 0 : 1)};

  &:not(:hover) .hover {
    display: none;
  }

  &:hover .no-hover {
    display: none;
  }

  cursor: pointer;

  @media ${breakpoints.isMobile} {
    width: 100%;
    height: calc(100vw * 0.5625);
    min-width: 100%;
    background-size: cover;
    background-position: center;
    order: 0;
  }

  @media ${breakpoints.isTablet} {
    order: 0;
  }
`;
const ResultItemInfoWinner = styled.div<{ even: boolean }>`
  width: 100%;
  order: ${(props) => (props.even ? 1 : 0)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;

  @media ${breakpoints.isMobile} {
    flex-direction: row;
    padding-left: inherit;
    justify-content: space-between;
    margin-top: 10px;
  }

  @media ${breakpoints.isTablet} {
    flex-direction: row;
    padding-left: inherit;
    justify-content: inherit;
    margin-top: 10px;
    align-items: center;
  }
`;

const ResultItemFinalist = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const FinalistCategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-margin: 10px;
  margin-bottom: 10px;
`;

const FinalListCategoryHeaderBadge = styled.img`
  width: 76px;
  padding: 10px;
`;

const WinnerCategoryHeaderBadge = styled.img`
  width: 141px;
  height: 141px;
  @media ${breakpoints.isMobile} {
    order: 1;
    width: 76px;
    height: 76px;
  }
`;

const FinalListCategoryHeaderName = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
`;

const FinalistEntries = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  grid-gap 20px;

  @media ${breakpoints.isTablet} {
    grid-template-columns: repeat(2, 3fr);
  }

  @media ${breakpoints.isMobile} {
    grid-template-columns: repeat(4, 3fr);
  }


`;

const PlayIconContainer = styled.div`
  width: 48px;
  height: 54px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  color: white;
  font-size: 25px;

  @media ${breakpoints.isMobile} {
    width: 20px;
    height: 20px;
    font-size: 15px;
  }
`;

const VideographerNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 9px;
  white-space: normal;

  @media ${breakpoints.isMobile} {
    font-size: 19px;
    line-height: 19px;
  }
`;

const WinnerInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  @media ${breakpoints.isMobile} {
    margin-left: 0;
    margin-top: 0;
    align-items: center;
  }

  @media ${breakpoints.isTablet} {
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 40px;
    margin-top: 0;
    align-items: center;
    :last-child {
      order: 1;
    }

    ::after {
      content: '';
      width: 100%;
    }
  }
`;

const WinnerParagraph = styled.div`
  margin-top: 20px;
  padding-right: 20px;

  @media ${breakpoints.isMobile} {
    order: 1;
    padding-right: 0;
    margin-top: 10px;
  }

  @media ${breakpoints.isTablet} {
    order: 1;
    padding-right: 0;
    margin-top: 15px;
  }
`;

const Bold = styled.span`
  font-weight: bold;
  display: contents;
  font-size: 22px;
  line-height: 22px;

  @media ${breakpoints.isMobile} {
    font-size: 19x;
    line-height: 22px;
  }
`;

interface Props {
  year: number;
  awardStatus: string;
  competition: ICompetition;
  competitionDates?: any[];
  hideHeader?: boolean;
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};


const SeeAllButton = styled(LSTVButton)`
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: white;
  color: ${LSTVGlobals.BLACK};
  border: 2px solid #000000;
  box-sizing: border-box;
  cursor: pointer;
`;

const DesktopSeeAllButton = styled(SeeAllButton)`
  width: 200px;
  height: 37px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const SponsorsImage = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 558.5px;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
`;

const formatTitle = (title: string) => {
  let parts = title.split('|');
  let names = undefined;
  let locations = undefined;
  if (parts.length > 0) {
    names = parts[0].replace('+', '&');
  }
  if (parts.length > 1) {
    locations = parts[1].replace(', United States', '').replace(' ,', ', ').replace('Phuket, Phuket', 'Phuket');
  }
  return `${names} in ${locations}`;
};

const getIndividualItems = (items) => {
  return items
    .filter((item) => item.status !== 'entry')
    .map((entry, index) => {
      if (entry.entry === 'video')
        return (
          <VideoCard
            blankTarget
            key={index}
            slug={entry.entry === 'video' ? entry.video_slug : `business/${entry.business_slug}`}
            title={entry.entry_video_title}
            videographerImageUrl={entry.business_logo_image || undefined}
            videographerName={entry.entry_business_name}
            thumbnailUrl={entry.entry_video_thumbnail.replace('-orig', '-mbl')}
            vibes={entry.entry === 'video' ? entry.video_vibes.slice(0, 3) ?? [] : undefined}
          />
        );
      else
        return (
          <VendorCard
            blankTarget
            noSendMessage
            key={index}
            slug={entry.business_slug}
            name={entry.entry_business_name}
            location={entry.business_location}
            thumbnailUrl={entry.entry_video_thumbnail.replace('-orig', '-mbl')}
            thumbnailAlt={`Wedding Business alt`}
            roles={[{ name: 'Videographer' }]}
            premium={true}
            avatarUrl={entry.business_logo_image}
            business={{
              name: entry.entry_business_name,
              slug: entry.business_slug,
              roles: [{ name: 'Videographer', slug: 'videographer' }]
            }}
            extraThumbnailUrls={entry.extra_thumbnail_urls}
          />
        );
    });
};

const CompetitionResults = (props: Props) => {
  let options = (props.competitionDates || []).map((item) => ({
    id: item.id,
    label: item.label,
    slug: item.slug
  }));
  const [showConfetti, setShowConfetti] = useState(false);
  const { ready, loggedIn, user } = useAuthService();
  const [option, setOption] = React.useState(
    options.filter((item) => item.id.includes(props.awardStatus) && item.id.includes(props.competition.id))[0]
  );

  useEffect(() => {
    let initialShow = (props.awardStatus == 'winners' && new Date().getFullYear() === props.year);
    if (initialShow) {
      const key = `confetti-${props.competition.name}-${props.year}`;
      if (localStorage?.getItem(key)) {
        setShowConfetti(false);
      } else {
        localStorage?.setItem(key, 'true');
        setShowConfetti(true);
      }
    }
  }, [props.awardStatus, props.year, props.competition.name, user]);

  const renderForDevice = (isMobile: boolean, isTablet: Boolean, isDesktop) => {
    const { year, awardStatus, competition, competitionDates } = props;
    const headerText = `${competition.name} ${awardStatus[0].toUpperCase()}${awardStatus.slice(1)}`;

    const headerSubtitle =
      awardStatus == 'finalists'
        ? `We're thrilled to reveal the ${year} finalists for the Wedding Film Awards. Thousands of filmmakers across the globe submitted their work, all of which truly reflect the highest standards of excellence in the wedding film industry.`
        : `We are honored to recognize the winners of the ${year - 2016}th Annual Wedding Film Awards by Love Stories TV. Join us in celebrating the innovation, artistry and breathtaking work of our ${year} winners.`;
    let items = null;
    let tieOffset = 0;
    const topCategories = ['filmmaker of the year', 'film of the year', 'wedding of the year'];
    if (awardStatus === 'winners') {
      items = competition.categories
        .filter((item) => {
          return item.results?.filter((item) => item.status === 'winner').length > 0;
        })
        .sort((a, b) => {
          const aIsTop = topCategories.includes(a.name.toLowerCase()) ? 1 : 0;
          const bIsTop = topCategories.includes(b.name.toLowerCase()) ? 1 : 0;
          return bIsTop - aIsTop;
        })
        .map((item, index) => {
          let entry = item.results.filter((item) => item.status === 'winner')[0];
          let entry2 = item.results.filter((item) => item.status === 'winner')[1];
          let url = entry.entry === 'video' ? `/${entry.video_slug}` : `/business/${entry.business_slug}`;
          let url2 = undefined;
          if (entry2) {
            url2 = entry2.entry === 'video' ? `/${entry2.video_slug}` : `/business/${entry2.business_slug}`;
            if (Math.random() < 0.5) {
              let entry3 = entry2;
              let url3 = url2;
              entry2 = entry;
              url2 = url;
              entry = entry3;
              url = url3;
            }

          }

          return (
            <div key={index}>

              <div key={index} style={{ borderLeft: entry2 ? '5px solid #B86747' : 'inherit',
                paddingLeft: entry2 ? '20px' : '0'}}>
                <ResultItemWinner>
                  <ResultItemThumbnailWinner
                    onClick={() => window.open(url, '_ blank')}
                    even={(index + tieOffset) % 2 == 0}
                    url={
                      entry.entry_video_thumbnail.replace('-orig', '-mbl') ||
                      entry.entry_business_thumbnail.replace('-orig', '-mbl')
                    }
                  >
                    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                      <PlayIconContainer>
                        <FontAwesomeIcon
                          className='no-hover'
                          icon={entry.entry == 'video' ? faPlay : faBriefcase}
                          size='lg'
                        />
                        <FontAwesomeIcon
                          className='hover'
                          icon={entry.entry == 'video' ? faPlaySolid : faBriefcaseSolid}
                          size='lg'
                        />
                      </PlayIconContainer>
                      <div style={{ flex: 1 }} />
                    </div>
                  </ResultItemThumbnailWinner>
                  <ResultItemInfoWinner even={(index + tieOffset) % 2 == 0}>
                    <WinnerCategoryHeaderBadge src={item.category_image} />
                    <WinnerInfo>
                      <Avatar
                        style={{ flex: '0 0 auto', height: '50px' }}
                        imageSrc={entry.business_logo_image}
                        initial={entry.entry_business_name.slice(0, 1)}
                        size='50px'
                      />
                      {entry.entry === 'video' && (
                        <VideographerNameContainer>
                          {formatTitle(entry.entry_video_title)}
                          <br />
                          by <Bold>{entry.entry_business_name}</Bold>
                        </VideographerNameContainer>
                      )}
                      {entry.entry === 'business' && (
                        <VideographerNameContainer>
                          <Bold>{entry.entry_business_name}</Bold>
                        </VideographerNameContainer>
                      )}
                      {isTablet && <WinnerParagraph>{item.description}</WinnerParagraph>}
                    </WinnerInfo>


                    {isDesktop && <WinnerParagraph>{item.description}</WinnerParagraph>}
                  </ResultItemInfoWinner>
                  {isMobile && <WinnerParagraph>{item.description}</WinnerParagraph>}

                </ResultItemWinner>
                {entry2 && <TieBar style={{ marginBottom: '30px' }}><Bold>{item.name} {year}</Bold>: Tie
                  between <Bold>{entry.entry_business_name}</Bold> and <Bold>{entry2.entry_business_name}</Bold></TieBar>}
                {entry2 && <ResultItemWinner>
                  <ResultItemThumbnailWinner
                    onClick={() => window.open(url2, '_ blank')}
                    even={(index + tieOffset) % 2 == 0}
                    url={
                      entry2.entry_video_thumbnail.replace('-orig', '-mbl') ||
                      entry2.entry_business_thumbnail.replace('-orig', '-mbl')
                    }
                  >
                    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                      <PlayIconContainer>
                        <FontAwesomeIcon
                          className='no-hover'
                          icon={entry2.entry == 'video' ? faPlay : faBriefcase}
                          size='lg'
                        />
                        <FontAwesomeIcon
                          className='hover'
                          icon={entry2.entry == 'video' ? faPlaySolid : faBriefcaseSolid}
                          size='lg'
                        />
                      </PlayIconContainer>
                      <div style={{ flex: 1 }} />
                    </div>
                  </ResultItemThumbnailWinner>
                  <ResultItemInfoWinner even={(index + tieOffset) % 2 == 0}>
                    <WinnerCategoryHeaderBadge src={item.category_image} />
                    <WinnerInfo>
                      <Avatar
                        style={{ flex: '0 0 auto', height: '50px' }}
                        imageSrc={entry2.business_logo_image}
                        initial={entry2.entry_business_name.slice(0, 0)}
                        size='50px'
                      />
                      {entry2.entry === 'video' && (
                        <VideographerNameContainer>
                          {formatTitle(entry2.entry_video_title)}
                          <br />
                          by <Bold>{entry2.entry_business_name}</Bold>
                        </VideographerNameContainer>
                      )}
                      {entry2.entry === 'business' && (
                        <VideographerNameContainer>
                          <Bold>{entry2.entry_business_name}</Bold>
                        </VideographerNameContainer>
                      )}
                      {isTablet && <WinnerParagraph>{item.description}</WinnerParagraph>}
                    </WinnerInfo>
                    {isDesktop && <WinnerParagraph>{item.description}</WinnerParagraph>}
                  </ResultItemInfoWinner>
                  {isMobile && <WinnerParagraph>{item.description}</WinnerParagraph>}
                </ResultItemWinner>}
              </div>

            </div>
          );
        });
      } else {
      items = competition.categories
        .filter((item) => {
          return item.results?.filter((item) => item.status !== 'entry').length > 0;
        })
        .map((item, index) => (
          <ResultItemFinalist key={index}>
            <FinalistCategoryHeader>
              <FinalListCategoryHeaderName>
                {props.hideHeader && <h3>{item.name}</h3>}
                {!props.hideHeader && <h2>{item.name}</h2>}
              </FinalListCategoryHeaderName>
              <FinalListCategoryHeaderBadge src={item.category_image} />
            </FinalistCategoryHeader>
            <FinalistEntries>
              {isMobile && <HorizontalScroll items={getIndividualItems(item.results)} />}
              {(isDesktop || isTablet) && getIndividualItems(item.results)}
            </FinalistEntries>
          </ResultItemFinalist>
        ));
    }

    return (
      <>
        {!props.hideHeader && (
          <StaticPageLayout bottomSeparator={true} wideContent headerText={headerText} headerSubtitle={headerSubtitle}>
            <a href="https://www.bhphotovideo.com/c/browse/sony-digital-photography/ci/5809" target="_blank" rel="noreferrer">
              <SponsorsImage
                src="/images/wfa-2024-sponsors-color-h.png"
                alt="Wedding Film Awards 2024 Sponsors"
              />
            </a>
            <ChipsSelect
              options={options}
              value={option}
              onChange={(option) => {
                setOption(option);
                router.push(`/${option.slug}`);
              }}
            />

            {/*
            <CompetitionHeader>
              <CompetitionDesc>
                <h5>Vote for your favorite finalists by clicking one of these category buttons. Voting is open till 11:59pm EST on July 3rd, 2024.</h5>
                <br/>
                <LSTVLink to={"/wedding-film-awards/vote/2024/peoples-choice"}>
                  <DesktopSeeAllButton eventAction="See all">Vote for People&apos;s Choice</DesktopSeeAllButton>
                </LSTVLink>
                &nbsp;&nbsp;
                <LSTVLink to={"/wedding-film-awards/vote/2024/wedding-of-the-year"}>
                  <DesktopSeeAllButton eventAction="See all">Vote for Wedding of the Year</DesktopSeeAllButton>
                </LSTVLink>
                {isVideographerUser(user) && <>
                  &nbsp;&nbsp;
                  <LSTVLink to={"/wedding-film-awards/vote/2024/film-of-the-year"}>
                    <DesktopSeeAllButton eventAction="See all">Vote for Film of the Year</DesktopSeeAllButton>
                  </LSTVLink>
                  &nbsp;&nbsp;
                  <LSTVLink to={"/wedding-film-awards/vote/2024/filmmaker-of-the-year"}>
                    <DesktopSeeAllButton eventAction="See all">Vote for Filmmaker of the Year</DesktopSeeAllButton>
                  </LSTVLink>
                </>}
              </CompetitionDesc>
              <CompetitionBadge src={competition.competition_badge_primary_0} />
            </CompetitionHeader>
            */}

            <ResultItems>{items}</ResultItems>
            {showConfetti && <Confetti />}
          </StaticPageLayout>
        )}
        {props.hideHeader && <ResultItems>{items}</ResultItems>}
      </>
    );
  };

  return (
    <>
      <Media between={['xs', 'sm']}>{renderForDevice(true, false, false)}</Media>
      <Media between={['sm', 'md']}>{renderForDevice(false, true, false)}</Media>
      <Media greaterThanOrEqual='md'>{renderForDevice(false, false, true)}</Media>
    </>
  );
};

export default CompetitionResults;
